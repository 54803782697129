<template>
  <svg viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6004 2.6H19.5004C20.2804 2.6 20.8004 3.12 20.8004 3.9V5.2H3.90039V3.9C3.90039 3.12 4.55039
      2.6 5.20039 2.6H9.10039C9.36039 1.17 10.7904 0 12.3504 0C13.9104 0 15.3404 1.17 15.6004 2.6ZM10.4004
      2.6H14.3004C14.0404 1.82 13.1304 1.3 12.3504 1.3C11.5704 1.3 10.6604 1.82 10.4004 2.6ZM5.20039
      6.5H19.5004L18.3304 19.63C18.3304 20.28 17.6804 20.8 17.0304 20.8H7.67039C7.02039 20.8 6.50039
      20.28 6.37039 19.63L5.20039 6.5Z"
      fill="#646995"
    />
  </svg>
</template>
