/* eslint-disable */

export type EditedOptions = OptionsCIMA | OptionsConsumerPCOA | OptionsEmailAppendAndValidate | OptionsLandlinePhoneAppend | OptionsNCOA | OptionsWirelessPhoneAppend;

export interface JobOptions {
  ncoa: OptionsNCOA;
  consumer_pcoa: OptionsConsumerPCOA;
  cima: OptionsCIMA;
  landline_phone_append: OptionsLandlinePhoneAppend;
  wireless_phone_append: OptionsWirelessPhoneAppend;
  email_append_and_validate: OptionsEmailAppendAndValidate;
}

export enum LANDLINE_PHONE_APPEND_MATCH_LEVEL {
  individual = 'individual',
  household = 'household',
  address = 'address',
}

export enum JOB_OPTIONS_APPEND {
  individual = 'individual',
  household = 'household',
}

export enum WIRELESS_PHONE_APPEND_APPEND_TYPE {
  standard = 'standard',
  premium = 'premium',
}

export interface OptionsNCOA {
  months: 18 | 48;
}

export interface OptionsConsumerPCOA {
  add_rooftop_long_lat: boolean;
  add_business_consumer_flag: boolean;
}

export interface OptionsCIMA {
  estimated_credit: boolean;
  select: boolean;
  cert: boolean;
}

export interface OptionsLandlinePhoneAppend {
  match_level: LANDLINE_PHONE_APPEND_MATCH_LEVEL;
  include_do_not_call_numbers: boolean;
  non_matches_use_wirelss_append: boolean;
}

export interface OptionsWirelessPhoneAppend {
  match_level: JOB_OPTIONS_APPEND;
  append_type: WIRELESS_PHONE_APPEND_APPEND_TYPE;
  include_do_not_call_numbers: boolean;
}

export interface OptionsEmailAppendAndValidate {
  match_level: JOB_OPTIONS_APPEND;
}

export type SuffixPrice = 'record' | 'match';
