export interface ModalsState {
  jobRate: boolean;
  changeSubscription: boolean;
}

export type ToggleModal = { modal: keyof ModalsState, status: boolean };

type ModalsStateKeys = (keyof ModalsState)[];

const state: ModalsState = {
  jobRate: false,
  changeSubscription: false,
};

const mutations = {
  toggle(state: ModalsState, payload: ToggleModal): void {
    state[payload.modal] = payload.status;
  },
  reset(state: ModalsState): void {
    const modals: ModalsStateKeys = Object.keys(state) as ModalsStateKeys;
    modals.forEach((v) => { state[v] = false; });
  },
};

const actions = {
  //
};

const getters = {
  //
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
