/* eslint-disable no-use-before-define, camelcase, import/no-cycle */

import { EditedOptions } from '@/common/JobOptions/jobOptions.interface';
import { PAYMENT_STATUS } from './billing.interface';

export enum JOB_TYPE {
  canadian_ncoa = 'canadian_ncoa',
  cima = 'cima',
  consumer_pcoa = 'consumer_pcoa',
  do_not_call_scrub = 'do_not_call_scrub',
  email_append_and_validate = 'email_append_and_validate',
  email_verification = 'email_verification',
  landline_phone_append = 'landline_phone_append',
  ncoa = 'ncoa',
  phone_disconnect = 'phone_disconnect',
  premium_business_phone_and_contact_append = 'premium_business_phone_and_contact_append',
  reverse_email = 'reverse_email',
  reverse_phone = 'reverse_phone',
  wireless_phone_append = 'wireless_phone_append',
  digital_audience = 'digital_audience',
}

export enum JOB_TYPE_TITLE {
  canadian_ncoa = 'Canadian NCOA',
  cima = 'CIMA',
  digital_audience = 'Digital audience',
  consumer_pcoa = 'Consumer PCOA',
  do_not_call_scrub = 'Do not call scrub',
  email_append_and_validate = 'Email append and validate',
  email_verification = 'Email verification',
  landline_phone_append = 'Landline phone append',
  ncoa = 'NCOA',
  phone_disconnect = 'Phone disconnect',
  premium_business_phone_and_contact_append = 'Premium Business phone and contact append',
  reverse_email = 'Reverse email',
  reverse_phone = 'Reverse phone',
  wireless_phone_append = 'Wireless phone append',
}

// STATUS

export enum JOB_STATUS_TITLE {
  ready = 'Ready',
  completed = 'Done',
  in_progress = 'In progress',
  ignored = 'Deleted',
  processing = 'Processing',
  mapping = 'Processing',
  error = 'Error',
}

export enum JOB_STATUS {
  ready = 'ready',
  completed = 'completed',
  in_progress = 'in_progress',
  ignored = 'ignored',
  processing = 'processing',
  mapping = 'mapping',
  error = 'error',
}

export type JobStatus = keyof typeof JOB_STATUS;

// JOB

export interface ShortJobInfoInstance {
  id: number;
  downloaded: boolean;
  estimated_price: number;
  file: string;
  final_price: number | null;
  rows: number;
  job_type: JOB_TYPE;
  status: JOB_STATUS;
  uploaded: string;
  errors: JobErrorMessage[];
}

export interface FullJobInstance extends ShortJobInfoInstance {
  valid_rows: number;
  size: number;
  item_price: number;
  possible_price: string;
  source: SOURCE_UPLOAD_JOB;
  options: EditedOptions;
  payment_status: PAYMENT_STATUS;
  result: JobResult;
}

export enum BILLING_ON {
  input = 'input',
  matches = 'matches',
}

export interface JobResult {
  billing_on: BILLING_ON;
  rows: number
  matches: number | null;
  custom_matches: number | null;
  report: any;
  archive: string
  files: string[]
}

export enum SOURCE_UPLOAD_JOB {
  web = 'web',
  ftp = 'ftp',
}

// ERROR

export enum JOB_ERROR_TYPE_TITLE {
  ftp_error = 'FTP error',
  not_enough_records = 'Not enough unique records',
  filesize_limit_exceeded = 'File size limit',
  fields_missing = 'Fields missing',
  duplicated_columns = 'Duplicated Columns'
}

export enum JOB_ERROR_TYPE {
  ftp_error = 'ftp_error',
  not_enough_records = 'not_enough_records',
  filesize_limit_exceeded = 'filesize_limit_exceeded',
  fields_missing = 'fields_missing',
  duplicated_columns = 'duplicated_columns'
}

export interface JobErrorMessage {
  message: string;
  error_type: JOB_ERROR_TYPE;
}

// JOB MAP

export interface JobMapFieldsFile {
  field: string;
  empty: number;
}
export interface JobMapFieldsData {
  [key: string]: string | null
}

export interface JobMapFields {
  rows: number | null;
  file_fields: JobMapFieldsFile[] | null;
  required_fields: string[] | null;
  alternatives: string[][][] | null;
  secondary_fields: string[] | null;
  data: JobMapFieldsData[] | null;
  possible_price: {
    standard: number;
    premium: number;
  } | null;
}
