/* eslint-disable no-restricted-syntax, no-prototype-builtins, no-restricted-properties */

import calcPrice from '@/common/JobOptions/calcPricing';
import { VALID_FORMAT_TYPE_FILE } from '@/settings';
import { JOB_TYPE } from '@/interface/job.interface';
import { SizesFile } from '@/interface/other.interface';
import { JobPricing } from '@/interface/pricing.interface';
import { JobOptions } from '@/common/JobOptions/jobOptions.interface';
import { ParserDate } from './date';

/**
 * Serialize JS Object with files to FormData
 * @param {any} dataObject
 * @return {FormData}
 */

export const toFormData = (dataObject: any): FormData => {
  const formData = new FormData();
  for (const k in dataObject) {
    if (dataObject.hasOwnProperty(k)) {
      if (dataObject[k] && dataObject[k].files) {
        const file = dataObject[k].files[0];
        formData.append(k, file, file.name);
      } else if (Array.isArray(dataObject[k])) {
        formData.append(k, JSON.stringify(dataObject[k]));
      } else if (typeof dataObject[k] === 'object' && !(dataObject[k] instanceof File)) {
        formData.append(k, JSON.stringify(dataObject[k]));
      } else {
        formData.append(k, dataObject[k]);
      }
    }
  }
  return formData;
};

export const validateTypeFile = (file: File): File | null => {
  const splitFileName = file.name.split('.');
  const fileFormat = splitFileName[splitFileName.length - 1];

  if (Object.keys(VALID_FORMAT_TYPE_FILE).some((v) => v === fileFormat)) {
    return file;
  }

  return null;
};

export const getPriceByType = (type: JOB_TYPE, options: JobOptions, pricing: JobPricing): string => {
  const resultCalc = calcPrice.calc(type, options, pricing);

  if (resultCalc !== null) {
    return `$${resultCalc.price}/${resultCalc.type}`;
  }

  const price = pricing[type];

  const priceKeys: (string | 'ppr' | 'ppm')[] = Object.keys(price);

  if (priceKeys.some((v) => v === 'ppr')) {
    return `$${(price as any).ppr}/record`;
  }

  if (priceKeys.some((v) => v === 'ppm')) {
    return `$${(price as any).ppm}/match`;
  }

  return '';
};

export const downloadFileByLink = (uri: string, target?: boolean): void => {
  const link = document.createElement('a');

  if (target) { link.target = '_blank'; }

  link.href = uri;
  link.rel = 'noopener noreferrer';
  link.click();
  URL.revokeObjectURL(link.href);

  link.remove();
};

/**
 * @param start ex: '2021-11';
 * @param end  ex: '2020-09';
 * @returns ['2021-11', '2021-10', ..., '2020-09'];
 */
export const possibleYearMonthDates = (start: string, end: string): string[] => {
  const dates: string[] = [];

  const startDate = start.split('-');
  const endDate = end.split('-');

  const date = { year: Number(startDate[0]), month: Number(startDate[1]) };

  let loop = true;

  while (loop) {
    dates.push(`${date.year}-${date.month >= 10 ? date.month : `0${date.month}`}`);

    if (date.year === Number(endDate[0]) && date.month === Number(endDate[1])) {
      loop = false;
      break;
    }

    if (date.month === 1) {
      date.year -= 1;
      date.month = 12;
    } else {
      date.month -= 1;
    }
  }

  return dates;
};

export const parserDateToYearmonth = (date: ParserDate): string => `${date.year.count}-${date.month.month}`;

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes: SizesFile[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
