import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "base-checkbox__container" }
const _hoisted_2 = ["disabled", "checked"]
const _hoisted_3 = { class: "base-checkbox--box" }
const _hoisted_4 = {
  key: 0,
  class: "u-s-n"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconsService = _resolveComponent("IconsService")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-checkbox base-element", { invalid: _ctx.$props?.errors?.length, disabled: _ctx.$props.disabled }])
  }, [
    _createElementVNode("label", _hoisted_1, [
      _createElementVNode("input", {
        type: "checkbox",
        disabled: _ctx.$props.disabled,
        checked: _ctx.$props.checked,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleCheckbox($event)))
      }, null, 40, _hoisted_2),
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_IconsService, {
          icon: "check",
          class: _normalizeClass({ check: _ctx.check })
        }, null, 8, ["class"])
      ]),
      (!_ctx.$slots.title && _ctx.$props.title)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$props.title), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "title")
    ])
  ], 2))
}