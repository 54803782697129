
import { Options, Vue } from 'vue-class-component';

import SimpleModal from '@/common/SimpleModal.vue';

import methods from '@/methods';
import requests from '@/requests';
import { SimpleModalButton } from '@/interface/other.interface';

@Options({
  components: {
    SimpleModal,
  },
})
export default class HijackingStripe extends Vue {
  public isShowModalRelease = false;

  public modalDeleteJobButtons: SimpleModalButton[] = [
    { button: 'border', title: 'No', value: 'no' },
    { button: 'fill', title: 'Yes', value: 'yes' },
  ];

  public handleActionReleaseModal(action: 'yes' | 'no'): void {
    if (action === 'yes') this.release();
    else this.isShowModalRelease = false;
  }

  private release() {
    this.$store.commit('setupToken', { hijacking: true, token: '' });

    requests.profile.getProfile().then((res) => {
      sessionStorage.removeItem('avatar');

      methods.store.setupProfile(res.data);

      window.location.reload();
    });
  }
}
