import auth from './auth';
import billing from './billing';
import dashboard from './dashboard';
import profile from './profile';
import blob from './blob';
import api from './api';

class Requests {
  public auth = auth;

  public profile = profile;

  public dashboard = dashboard;

  public billing = billing;

  public blob = blob;

  public api = api;
}

export default new Requests();
