/* eslint-disable max-len, no-bitwise, prefer-template */
// TODO: REFACTORING PRICE;

import { JOB_TYPE } from '@/interface/job.interface';

import {
  JobPricing,
  PricingCIMA,
  PricingConsumerPCOA,
  PricingEmailAppendAndValidate,
  PricingLandlinePhoneAppend,
  PricingNCOA,
  PricingWirelessPhoneAppend,
} from '@/interface/pricing.interface';

import {
  JobOptions,
  OptionsCIMA,
  OptionsConsumerPCOA,
  OptionsEmailAppendAndValidate,
  OptionsLandlinePhoneAppend,
  OptionsNCOA,
  OptionsWirelessPhoneAppend,
  SuffixPrice,
  WIRELESS_PHONE_APPEND_APPEND_TYPE,
} from './jobOptions.interface';

export interface CalcOptionsResult {
  price: number;
  type: SuffixPrice;
}

class CalcOptions {
  public calc(type: JOB_TYPE, options: JobOptions, pricing: JobPricing): CalcOptionsResult | null{
    switch (type) {
      case JOB_TYPE.consumer_pcoa:
        return this.consumerPcoa(options.consumer_pcoa, pricing.consumer_pcoa);
      case JOB_TYPE.ncoa:
        return this.ncoa(options.ncoa, pricing.ncoa);
      case JOB_TYPE.wireless_phone_append:
        return this.wirelessPhoneAppend(options.wireless_phone_append, pricing.wireless_phone_append);
      case JOB_TYPE.email_append_and_validate:
        return this.emailAppendAndValidate(options.email_append_and_validate, pricing.email_append_and_validate);
      case JOB_TYPE.landline_phone_append:
        return this.landlinePhoneAppend(options.landline_phone_append, pricing.landline_phone_append);
      case JOB_TYPE.cima:
        return this.cima(options.cima, pricing.cima);
      default:
        return null;
    }
  }

  public detectedSuffixPrice(type: JOB_TYPE): SuffixPrice | null{
    switch (type) {
      case JOB_TYPE.consumer_pcoa:
        return 'record';
      case JOB_TYPE.ncoa:
        return 'record';
      case JOB_TYPE.wireless_phone_append:
        return 'match';
      case JOB_TYPE.email_append_and_validate:
        return 'match';
      case JOB_TYPE.landline_phone_append:
        return 'match';
      case JOB_TYPE.cima:
        return 'record';
      default:
        return 'match'; // TODO: узнать что по умолчанию ставить
    }
  }

  private consumerPcoa(options: OptionsConsumerPCOA, pricing: PricingConsumerPCOA): CalcOptionsResult {
    let toFix = pricing.ppr;
    let price = pricing.ppr;

    const f = (x: any) => (~(x + '').indexOf('.') ? (x + '').split('.')[1].length : 0);

    if (options.add_rooftop_long_lat) {
      price += pricing.add_rooftop_long_lat_price;
      const fix = f(pricing.add_rooftop_long_lat_price);

      if (toFix <= fix) {
        toFix = fix;
      }
    }
    if (options.add_business_consumer_flag) {
      price += pricing.add_business_consumer_flag_price;
      const fix = f(pricing.add_business_consumer_flag_price);

      if (toFix <= fix) {
        toFix = fix;
      }
    }
    if (toFix === pricing.ppr) {
      price = toFix;
    } else {
      price = Number(price.toFixed(toFix));
    }

    return { price, type: 'record' };
  }

  private ncoa(options: OptionsNCOA, pricing: PricingNCOA): CalcOptionsResult {
    switch (options.months) {
      case 18:
        return { price: pricing.price_18, type: 'record' };
      case 48:
        return { price: pricing.price_48, type: 'record' };
      default: // 48;
        return { price: pricing.price_48, type: 'record' };
    }
  }

  private wirelessPhoneAppend(options: OptionsWirelessPhoneAppend, pricing: PricingWirelessPhoneAppend): CalcOptionsResult {
    switch (options.append_type) {
      case WIRELESS_PHONE_APPEND_APPEND_TYPE.standard:
        return { price: pricing.standard_price, type: 'match' };
      case WIRELESS_PHONE_APPEND_APPEND_TYPE.premium:
        return { price: pricing.premium_price, type: 'match' };
      default:
        return { price: pricing.premium_price, type: 'match' };
    }
  }

  private emailAppendAndValidate(options: OptionsEmailAppendAndValidate, pricing: PricingEmailAppendAndValidate): CalcOptionsResult {
    return { price: pricing.ppm, type: 'match' };
  }

  private landlinePhoneAppend(options: OptionsLandlinePhoneAppend, pricing: PricingLandlinePhoneAppend): CalcOptionsResult {
    return { price: pricing.ppm, type: 'match' };
  }

  private cima(options: OptionsCIMA, pricing: PricingCIMA): CalcOptionsResult {
    let toFix = pricing.ppr;
    let price = 0;

    const f = (x: any) => (~(x + '').indexOf('.') ? (x + '').split('.')[1].length : 0);

    if (options.estimated_credit) {
      if (!options.select && !options.cert) {
        price += (pricing.ppr + pricing.estimated_credit_price);
        const fix = f(pricing.estimated_credit_price);

        if (toFix <= fix) {
          toFix = fix;
        }
      } else {
        price += pricing.estimated_credit_price;
        const fix = f(pricing.estimated_credit_price);

        if (toFix <= fix) {
          toFix = fix;
        }
      }
    }
    if (options.select) {
      price += pricing.select_price;
      const fix = f(pricing.select_price);

      if (toFix <= fix) {
        toFix = fix;
      }
    }
    if (options.cert) {
      price += pricing.cert_price;
      const fix = f(pricing.cert_price);

      if (toFix <= fix) {
        toFix = fix;
      }
    }
    if (toFix === pricing.ppr || price === 0) {
      price = toFix;
    } else if (toFix !== pricing.ppr) {
      if (price < 0.1) {
        price = Number(price.toFixed(2));
      } else {
        price = Number(price.toFixed(toFix));
      }
    }

    return { price, type: 'record' };
  }
}

export default new CalcOptions();
