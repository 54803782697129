
import { Options, Vue } from 'vue-class-component';

import IconsService from '@/icons/Service.vue';

@Options({
  components: {
    IconsService,
  },
  props: {
    title: String,
    errors: Array,
    disabled: Boolean,
    checked: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    selected: [String, Number, Object, Boolean],
    'update:value': [Boolean, Object],
  },
})
export default class BaseCheckbox extends Vue {
  declare $props: {
    title: string;
    disabled: boolean;
    checked: boolean;
    errors: string[];
  }

  public check = false;

  public handleCheckbox(e: Event): void {
    const status = (e.target as HTMLInputElement).checked;

    this.check = status;

    this.$emit('selected', this.check);
    this.$emit('update:value', this.check);
  }

  mounted(): void {
    this.check = this.$props.checked;
  }
}
