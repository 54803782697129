import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "base-dropdown__container" }
const _hoisted_2 = {
  key: 0,
  class: "m-bottom-9"
}
const _hoisted_3 = { class: "base-dropdown__head--slot" }
const _hoisted_4 = {
  key: 0,
  class: "placeholder"
}
const _hoisted_5 = { class: "base-dropdown__arrow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSmallArrow = _resolveComponent("IconSmallArrow")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$props.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$props.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["base-dropdown", { 'no-head': !_ctx.$slots.head, disabled: _ctx.$props.disabled }])
    }, [
      _createElementVNode("div", {
        role: "button",
        class: _normalizeClass(["base-dropdown__head", { 'is-show-list': _ctx.showList }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShowDropdown && _ctx.toggleShowDropdown(...args)))
      }, [
        _createElementVNode("div", _hoisted_3, [
          (!_ctx.$slots.head)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$props.placeholder), 1)
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "head")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_IconSmallArrow, { direction: "top" }),
          _createVNode(_component_IconSmallArrow, { direction: "bottom" })
        ])
      ], 2),
      (_ctx.showList)
        ? _withDirectives((_openBlock(), _createBlock(_component_perfect_scrollbar, {
            key: 0,
            class: "base-dropdown__list",
            tag: "div"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$props.listTag), { onClick: _ctx.closeDropdown }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "list")
                ]),
                _: 3
              }, 8, ["onClick"]))
            ]),
            _: 3
          })), [
            [_directive_click_outside, _ctx.closeDropdown]
          ])
        : _createCommentVNode("", true)
    ], 2)
  ]))
}