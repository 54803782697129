
import { Options, Vue } from 'vue-class-component';

import IconSmallArrow from '@/icons/service/SmallArrow.vue';

@Options({
  components: {
    IconSmallArrow,
  },
  props: {
    disabled: Boolean,
    listTag: {
      type: String,
      default: 'ul',
    },
    title: String,
    placeholder: {
      type: String,
      default: 'Placeholder',
    },
  },
})
export default class BaseDropdown extends Vue {
  declare $props: {
    disabled: boolean;
    listTag: string;
    title: string;
    placeholder: string;
  }

  public showList = false;

  public toggleShowDropdown(): void {
    if (!this.$props.disabled) {
      this.showList = !this.showList;
    }
  }

  public closeDropdown(): void {
    this.showList = false;
  }
}
