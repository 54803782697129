
import { Options, Vue } from 'vue-class-component';

import Modal from '@/common/base/Modal.vue';
import { SimpleModalButton } from '@/interface/other.interface';

@Options({
  components: {
    Modal,
  },
  props: {
    title: String,
    subtitle: String,
    buttons: Array,
  },
  emits: {
    action: String,
    close: Object,
  },
})
export default class SimpleModal extends Vue {
  declare $props: {
    title: string;
    subtitle: string;
    buttons: SimpleModalButton[];
  }

  public handleClick(value: string): void {
    this.$emit('action', value);
  }

  public closeModal(): void {
    this.$emit('close');
  }
}
