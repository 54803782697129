/* eslint-disable  @typescript-eslint/no-explicit-any, max-len */

import axios, { AxiosRequestConfig } from 'axios';
import store from '@/store';

import { JOB_TYPE } from '@/interface/job.interface';

import { toFormData } from '@/utils';
import { BlobParts, LOADER_STATUS } from '@/interface/other.interface';
import { UploadedBlobResponse, UploadedFileResponse } from '@/interface/requests.interface';

export class RequestBlob {
  private data!: BlobParts;

  private jobType!: JOB_TYPE;

  private numberChunk = 0;

  private offset = 0;

  private endpoint = '/dashboard/jobs/chunked-upload';

  public uploadFile(data: BlobParts, jobType: JOB_TYPE): void {
    this.data = data;
    this.jobType = jobType;

    this.numberChunk = 0;
    this.offset = 0;

    if (data.parts.length === 1) {
      this.uploadWholeFile();
    } else {
      this.uploadBlobPart(this.endpoint);
    }
  }

  /**
   * @description upload file if he consists of one blob;
   */
  private uploadWholeFile(): void {
    const payload = toFormData({
      filename: this.data.name,
      job_type: this.jobType,
      md5: this.data.md5,
      file: new File([this.data.parts[this.numberChunk]], this.data.name),
    });

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Range': `bytes ${this.offset}-${this.data.parts[this.numberChunk].size - 1 + this.offset}/${this.data.size}`,
        Authorization: this.getAuthorizationToken,
      },
    };

    this.updateCurrentStopLoader(this.numberChunk);

    axios.post<UploadedFileResponse>(`${process.env.VUE_APP_BACKEND_URL}${this.endpoint}`, payload, config)
      .then(() => { this.updateCurrentStopLoader(LOADER_STATUS.finished); })
      .catch(() => { this.errorUpload(); });
  }

  /**
   * @param {string} endpoint - endpoint for upload blob;
   * @description upload one of blob;
   */
  private uploadBlobPart(endpoint: string): void {
    const payload = toFormData({
      filename: this.data.name,
      job_type: this.jobType,
      file: new File([this.data.parts[this.numberChunk]], this.data.name),
    });

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Range': `bytes ${this.offset}-${this.data.parts[this.numberChunk].size - 1 + this.offset}/${this.data.size}`,
        Authorization: this.getAuthorizationToken,
      },
    };

    this.updateCurrentStopLoader(this.numberChunk);

    axios.put<UploadedBlobResponse>(`${process.env.VUE_APP_BACKEND_URL}${endpoint}`, payload, config)
      .then((res) => {
        this.numberChunk += 1;
        this.offset = res.data.offset;

        if (this.numberChunk === this.data.parts.length) {
          this.finishUploadBlob(res.data.url);
        } else {
          this.uploadBlobPart(res.data.url);
        }
      })
      .catch(() => { this.errorUpload(); });
  }

  /**
   * @param {string} endpoint - endpoint for upload blob;
   * @description upload last blob;
   */
  private finishUploadBlob(endpoint: string): void {
    const payload = toFormData({
      filename: this.data.name,
      job_type: this.jobType,
      md5: this.data.md5,
    });

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: this.getAuthorizationToken,
      },
    };

    axios.post<UploadedFileResponse>(`${process.env.VUE_APP_BACKEND_URL}${endpoint}`, payload, config)
      .then(() => { this.updateCurrentStopLoader(LOADER_STATUS.finished); })
      .catch(() => { this.errorUpload(); });
  }

  private errorUpload(): void {
    this.updateCurrentStopLoader(LOADER_STATUS.error);
  }

  private updateCurrentStopLoader(status: LOADER_STATUS | number): void {
    store.commit('updateCurrentStopLoader', status);
  }

  get getAuthorizationToken(): string {
    const token = store.state.hijackingToken || store.state.token;

    return `Bearer ${token}`;
  }
}

export default new RequestBlob();
