import axios, { AxiosPromise } from 'axios';

import http from '@/utils/http';
import { ProfileInstance } from '@/interface/profile.interface';
import { TutorialsList } from '@/interface/other.interface';
import {
  ConfirmAccountResponse,
  FormResponse,
  SendPAFFormResponse,
  SignUpResponse,
} from '@/interface/requests.interface';

class RequestsProfile {
  public getProfile(): AxiosPromise<ProfileInstance> {
    return http.get<ProfileInstance>('/profile/');
  }

  public getPAFForm(): AxiosPromise<FormResponse> {
    return http.get<FormResponse>('/profile/paf/');
  }

  public signItPAForm(): AxiosPromise<any> {
    return http.post<any>('/profile/paf/');
  }

  public sendPAFFormOnEmail(): AxiosPromise<SendPAFFormResponse> {
    return http.post<SendPAFFormResponse>('/profile/paf/send');
  }

  public resetPassword(email: string, withHeader?: boolean): AxiosPromise<{ status: 'OK' } | { email: string }> {
    const endpoint = '/profile/password_reset';

    if (withHeader) {
      return http.post<{ status: 'OK' }>(endpoint, { email });
    }

    return axios.post<{ email: string }>(`${process.env.VUE_APP_BACKEND_URL}${endpoint}`, { email });
  }

  public patchProfile(profile: any): AxiosPromise<ProfileInstance> {
    return http.patch<ProfileInstance>('/profile/', profile);
  }

  public signUp(form: { [key: string]: any }): AxiosPromise<SignUpResponse> {
    return axios.post<SignUpResponse>(`${process.env.VUE_APP_BACKEND_URL}/profile/signup/`, form);
  }

  public resendConfirmEmail(email: string): AxiosPromise<{ email: string }> {
    return axios.post<{ email: string }>(`${process.env.VUE_APP_BACKEND_URL}/profile/validate_email/request`,
      { email });
  }

  public confirmAccount(data: { email: string, code: string }): AxiosPromise<ConfirmAccountResponse> {
    return axios.post<ConfirmAccountResponse>(`${process.env.VUE_APP_BACKEND_URL}/profile/validate_email/confirm`,
      data);
  }

  public validationResetPasswordToken(token: string): AxiosPromise<{ status: 'OK' }> {
    return axios.post<{ status: 'OK' }>(`${process.env.VUE_APP_BACKEND_URL}/profile/password_reset/validate_token`, {
      token,
    });
  }

  public confirmResetPassword(data: { password: string, token: string }): AxiosPromise<any> {
    return axios.post<any>(`${process.env.VUE_APP_BACKEND_URL}/profile/password_reset/confirm`, data);
  }

  public getListCompletedTutorial(): AxiosPromise<TutorialsList> {
    return http.get<TutorialsList>('/profile/tooltips/');
  }

  public patchStatusTutorial(tutorial: { [key: string]: boolean }): AxiosPromise<TutorialsList> {
    return http.patch<TutorialsList>('/profile/tooltips/', tutorial);
  }
}

export default new RequestsProfile();
