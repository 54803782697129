import { App } from 'vue';

import BaseInput from '@/common/base/BaseInput.vue';
import BaseRadio from '@/common/base/BaseRadio.vue';
import BaseCheckbox from '@/common/base/BaseCheckbox.vue';
import BaseDropdown from '@/common/base/BaseDropdown.vue';

function init(app: App<Element>): void {
  app.component('base-input', BaseInput);
  app.component('base-radio', BaseRadio);
  app.component('base-checkbox', BaseCheckbox);
  app.component('base-dropdown', BaseDropdown);
}

export default init;
