
import { Options, Vue } from 'vue-class-component';

import Logo from '@/common/Logo.vue';
import IconScreenIsNotSupported from '@/icons/other/ScreenIsNotSupported.vue';

@Options({
  components: {
    Logo,
    IconScreenIsNotSupported,
  },
})
export default class ScreenIsNotSupported extends Vue {
  mounted(): void {
    document.body.style.setProperty('overflow', 'hidden', 'important');
  }

  unmounted(): void {
    document.body.removeAttribute('style');
  }
}
