import { AxiosPromise } from 'axios';

import http from '@/utils/http';
import {
  BillingHistory,
  BillingHistoryAPI,
  BillingJobRates,
} from '@/interface/billing.interface';
import { toFormData } from '@/utils';
import { AvailableBilling, PaymentMethod } from '@/interface/payment.interface';
import { DownloadBillingReportResponse } from '@/interface/requests.interface';
import { JobPricing } from '@/interface/pricing.interface';
import { SUBSCRIPTION_PLAN } from '@/subscription';
import store from '@/store';
import { ProfileInstance, SubscriptionInstance } from '@/interface/profile.interface';

class RequestsBilling {
  public getAvailableBillingMethods(): AxiosPromise<AvailableBilling> {
    return http.get<AvailableBilling>('/billing/');
  }

  public getJobPricing(): AxiosPromise<JobPricing> {
    return http.get<JobPricing>('/billing/pricing');
  }

  public getUserSpecialOptions(userId: string): AxiosPromise<any> {
    return http.get<any>(`/billing/pricing/user_special_options/${userId}`);
  }

  public downloadBillingReport(date: string): AxiosPromise<DownloadBillingReportResponse> {
    return http.post<DownloadBillingReportResponse>(`/billing/usage/report/${date}`);
  }

  public getBillingHistory(date: string): AxiosPromise<BillingHistory> {
    return http.get<BillingHistory>(`/billing/usage/${date}`);
  }

  public getBillingHistoryAPI(date: string): AxiosPromise<BillingHistoryAPI> {
    return http.get<BillingHistoryAPI>(`/billing/usage/api/${date}`);
  }

  public getBillingJobRates(date: string): AxiosPromise<BillingJobRates[]> {
    return http.get<BillingJobRates[]>(`/billing/usage/${date}/top`);
  }

  public addNewPaymentMethod(pmId: string): AxiosPromise<AvailableBilling> {
    return http.post<AvailableBilling>('/billing/', toFormData({ payment_method: pmId }));
  }

  public setDefaultPM(pmId: string): AxiosPromise<PaymentMethod> {
    return http.put<PaymentMethod>('/billing/payment-method', toFormData({ stripe_id: pmId }));
  }

  public deletePM(pmId: string): AxiosPromise<void> {
    return http.delete<void>('/billing/payment-method', { data: toFormData({ stripe_id: pmId }) });
  }

  public updateSubscription(plan: SUBSCRIPTION_PLAN): AxiosPromise<SubscriptionInstance> {
    const profile: ProfileInstance = store.state.profile!;
    const subscription = { ...profile.subscription };

    const endpoint = '/billing/subscription';

    if (subscription.plan === SUBSCRIPTION_PLAN.free || !subscription.active) {
      return http.post(endpoint, { plan });
    }

    if (plan === SUBSCRIPTION_PLAN.free) {
      return http.put(endpoint, { cancel: true });
    }

    return http.put(endpoint, { plan });
  }
}

export default new RequestsBilling();
