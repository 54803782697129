
import { Options, Vue } from 'vue-class-component';
import { watch, watchEffect, WatchStopHandle } from 'vue';

@Options({
  props: {
    title: String,
    errors: Array,
    bind: Object,
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    focus: Boolean,
  },
  emits: {
    change: [String, Number, Object],
    'update:value': [String, Number, Object],
    blur: Boolean,
    focus: Boolean,
  },
})

/* eslint-disable no-underscore-dangle */

export default class BaseInput extends Vue {
  declare $props: {
    title: string;
    errors: string[];
    type: string;
    placeholder: string;
    disabled: boolean;
    value: string;
    focus: boolean;
    bind: { [key: string]: any };
  }

  declare $refs: {
    input: HTMLInputElement
  }

  public value_ = '';

  public wereErrors = false;

  public isStatusFocus = false;

  public watchStopHandler!: WatchStopHandle;

  public updateValue(v: string): void {
    this.value_ = v;
    this.$emit('change', v || null);
    this.$emit('update:value', v || null);
  }

  public blur(): void {
    this.isStatusFocus = false;

    this.$emit('blur', true);
  }

  public handleStatusFocus(): void {
    this.isStatusFocus = true;

    this.$emit('focus', true);
  }

  created(): void {
    this.value_ = this.$props.value;

    this.watchStopHandler = watchEffect(() => {
      if (this.$props?.errors?.length > 0) this.wereErrors = true;
    });
  }

  mounted(): void {
    if (this.$props.focus) this.$refs.input.focus();
  }

  unmounted(): void {
    this.watchStopHandler();
  }
}
