/* eslint-disable max-len */

import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { LOADER_STATUS } from '@/interface/other.interface';

import modals from './modules/modals';

export default createStore({
  state: {
    isSmartphone: false,
    token: '',
    hijackingToken: '',
    showModalLoader: false,
    countOpenedModal: 0,
    data: null,
    currentStop: -1,
    tutorial: {},
    profile: null,
    update: '',
    specialOptions: { phone_disconnect_on_match: false },
  },
  mutations: {
    changeIsSmartphone(state, payload: boolean): void {
      state.isSmartphone = payload;
    },
    setupToken(state, payload: { hijacking: boolean, token: string }): void {
      if (payload.hijacking) {
        state.hijackingToken = payload.token;
      } else {
        state.token = payload.token;
      }
    },
    changeShowModalLoader(state, payload: boolean): void {
      state.showModalLoader = payload;
    },
    changeCountOpenedModal(state, payload: number): void {
      state.countOpenedModal += payload;
    },
    resetState(state): void {
      state.countOpenedModal = 0;
      state.showModalLoader = false;
    },
    trackUserSpecialOptions(state, payload: any): void {
      state.specialOptions = payload;
    },
    clearUserSpecialOptions(state, payload: any): void {
      state.specialOptions = payload;
    },
    /**
     * @param payload - if typeof payload === number - his means that the loader will stop at the percentage in the array element, which is determined of this payload;
     * @param payload - if typeof payload === LOADER_STATUS - his means that it will execute one of the scripts that is written inside the component;
     */
    updateCurrentStopLoader(state, payload: LOADER_STATUS | number): void {
      state.currentStop = payload as any;
    },
    changeData(state, payload: any): void {
      state.data = payload;
    },
    // this trigger for update some elements;
    update(state, payload: string): void {
      state.update = payload;
    },
    // eslint-disable-next-line
    set(state: any, payload: { key: string, payload: any }): void {
      state[payload.key] = payload.payload;
    },
  },
  actions: {
  },
  modules: {
    modals,
  },
  plugins: [createPersistedState({ key: 'session' })],
});
