import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "base-input__icon" }
const _hoisted_3 = ["type", "disabled", "placeholder", "value"]
const _hoisted_4 = { class: "base-element__errors" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-input base-element", {
      invalid: _ctx.$props?.errors?.length,
      valid: !_ctx.$props?.errors?.length && _ctx.wereErrors && _ctx.value_,
      focus: _ctx.isStatusFocus && (!_ctx.$props?.errors?.length && !_ctx.wereErrors || !_ctx.$props?.errors?.length && !_ctx.value_),
      dirty: !_ctx.isStatusFocus && !_ctx.$props?.errors?.length && !_ctx.wereErrors && _ctx.value_
    }])
  }, [
    (!_ctx.$slots.title || _ctx.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "title"),
    _createElementVNode("label", null, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "icon")
      ]),
      _createElementVNode("input", _mergeProps({ ref: "input" }, _ctx.$props.bind, {
        class: { 'with-icon': _ctx.$slots.icon, 'has-value': _ctx.value_ },
        type: _ctx.type,
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder || _ctx.title,
        value: _ctx.value_,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateValue($event.target.value))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleStatusFocus && _ctx.handleStatusFocus(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args)))
      }), null, 16, _hoisted_3)
    ]),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.errors, (msg, id) => {
        return (_openBlock(), _createElementBlock("li", {
          key: id,
          class: "base-element__errors--msg t-a-r"
        }, [
          _createElementVNode("span", null, _toDisplayString(msg), 1)
        ]))
      }), 128))
    ])
  ], 2))
}