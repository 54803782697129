import BMF from 'browser-md5-file';
import { BlobParts } from '@/interface/other.interface';

class HashFile {
  private getHash(file: File): Promise<string> {
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    return new Promise((resolve) => {
      const bmf = new BMF();

      bmf.md5(file, (err: any, md5: string) => {
        resolve(md5);
      });
    });
  }

  public getBlobParts(file: File, chunk = 4, multiply = 1000000): Promise<BlobParts> {
    const chunkSize = chunk * multiply;
    const BlobPart: Blob[] = [];

    return this.getHash(file).then((md5: string) => new Promise<BlobParts>((resolve) => {
      let sumChunk = 0;

      for (let start = 0; start < file.size; start += chunkSize) {
        const chunk = file.slice(start, start + chunkSize, file.type);
        BlobPart.push(chunk);
        sumChunk += chunk.size;

        if (sumChunk === file.size) {
          resolve({
            md5,
            parts: BlobPart,
            size: file.size,
            name: file.name,
          });
        }
      }
    }));
  }
}

export default new HashFile();
