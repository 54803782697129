<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M46.5389 176.85C84.9666 176.85 111.356 196.756 129.872 196.756C148.389 196.756 187.278 186.111 187.278 100.928C187.278 15.7444 134.961 3.23889 114.594 3.23889C19.2222 3.23889 -21.5167 176.85 46.5389 176.85Z" fill="#FDFFFC"/>
    <path d="M143.924 11.7777L147.852 7.84934L155.709 15.7061L151.781 19.6345L143.924 11.7777Z" fill="#646995"/>
    <path d="M166.509 34.3722L170.438 30.4438L178.294 38.3006L174.366 42.2289L166.509 34.3722Z" fill="#646995"/>
    <path d="M165.51 15.686L173.367 7.82929L177.295 11.7577L169.439 19.6144L165.51 15.686Z" fill="#646995"/>
    <path d="M151.389 156.944H48.6111C42.4722 156.944 37.5 151.972 37.5 145.833V62.5H162.5V145.833C162.5 151.972 157.528 156.944 151.389 156.944Z" fill="#F3F7FC"/>
    <path d="M162.5 62.5H37.5V54.1667C37.5 48.0278 42.4722 43.0555 48.6111 43.0555H151.389C157.528 43.0555 162.5 48.0278 162.5 54.1667V62.5Z" fill="#FFA400"/>
    <path d="M72.2223 101.389C76.0576 101.389 79.1667 98.2798 79.1667 94.4445C79.1667 90.6091 76.0576 87.5 72.2223 87.5C68.387 87.5 65.2778 90.6091 65.2778 94.4445C65.2778 98.2798 68.387 101.389 72.2223 101.389Z" fill="#FFA400"/>
    <path d="M127.778 101.389C131.613 101.389 134.722 98.2798 134.722 94.4445C134.722 90.6091 131.613 87.5 127.778 87.5C123.943 87.5 120.833 90.6091 120.833 94.4445C120.833 98.2798 123.943 101.389 127.778 101.389Z" fill="#FFA400"/>
    <path d="M61.1111 43.0555H48.6111C42.4722 43.0555 37.5 48.0278 37.5 54.1667V62.5H50V54.1667C50 48.0278 54.9722 43.0555 61.1111 43.0555Z" fill="#FFA400"/>
    <path d="M77.7778 94.4445C77.7778 93.3889 78.0501 92.4167 78.4723 91.5222C77.3612 89.1611 75.0001 87.5 72.2223 87.5C68.3889 87.5 65.2778 90.6111 65.2778 94.4445C65.2778 98.2778 68.3889 101.389 72.2223 101.389C75.0001 101.389 77.3612 99.7278 78.4723 97.3667C78.0501 96.4722 77.7778 95.5 77.7778 94.4445Z" fill="#FFA400"/>
    <path d="M133.333 94.4445C133.333 93.3889 133.606 92.4167 134.028 91.5222C132.917 89.1611 130.556 87.5 127.778 87.5C123.944 87.5 120.833 90.6111 120.833 94.4445C120.833 98.2778 123.944 101.389 127.778 101.389C130.556 101.389 132.917 99.7278 134.028 97.3667C133.606 96.4722 133.333 95.5 133.333 94.4445Z" fill="#FFA400"/>
    <path d="M50 145.833V62.5H37.5V145.833C37.5 151.972 42.4722 156.944 48.6111 156.944H61.1111C54.9722 156.944 50 151.972 50 145.833Z" fill="#FDFFFC"/>
    <path d="M72.2223 105.556C66.0945 105.556 61.1112 100.572 61.1112 94.4445C61.1112 88.3167 66.0945 83.3334 72.2223 83.3334C78.3501 83.3334 83.3334 88.3167 83.3334 94.4445C83.3334 100.572 78.3501 105.556 72.2223 105.556ZM72.2223 91.6667C70.689 91.6667 69.4445 92.9167 69.4445 94.4445C69.4445 95.9723 70.689 97.2223 72.2223 97.2223C73.7557 97.2223 75.0001 95.9723 75.0001 94.4445C75.0001 92.9167 73.7557 91.6667 72.2223 91.6667Z" fill="#383B53"/>
    <path d="M127.778 105.556C121.65 105.556 116.667 100.572 116.667 94.4445C116.667 88.3167 121.65 83.3334 127.778 83.3334C133.906 83.3334 138.889 88.3167 138.889 94.4445C138.889 100.572 133.906 105.556 127.778 105.556ZM127.778 91.6667C126.244 91.6667 125 92.9167 125 94.4445C125 95.9723 126.244 97.2223 127.778 97.2223C129.311 97.2223 130.556 95.9723 130.556 94.4445C130.556 92.9167 129.311 91.6667 127.778 91.6667Z" fill="#383B53"/>
    <path d="M129.461 144.783L126.517 141.839C111.894 127.222 88.1055 127.222 73.4833 141.839L70.5389 144.783L64.6444 138.889L67.5889 135.944C85.4611 118.072 114.533 118.072 132.406 135.944L135.35 138.889L129.461 144.783Z" fill="#383B53"/>
    <path d="M151.389 161.111H48.6112C40.1834 161.111 33.3334 154.261 33.3334 145.833V54.1666C33.3334 45.7388 40.1834 38.8888 48.6112 38.8888H151.389C159.817 38.8888 166.667 45.7388 166.667 54.1666V145.833C166.667 154.261 159.817 161.111 151.389 161.111ZM48.6112 47.2221C44.7834 47.2221 41.6667 50.3388 41.6667 54.1666V145.833C41.6667 149.661 44.7834 152.778 48.6112 152.778H151.389C155.217 152.778 158.333 149.661 158.333 145.833V54.1666C158.333 50.3388 155.217 47.2221 151.389 47.2221H48.6112Z" fill="#383B53"/>
    <path d="M37.5 58.3334H162.5V66.6667H37.5V58.3334Z" fill="#383B53"/>
    <path d="M11.1112 152.778H188.889V161.111H11.1112V152.778Z" fill="#383B53"/>
  </svg>
  <!-- eslint-enable -->
</template>
