import { SUBSCRIPTION_PLAN } from '@/subscription';

import store from '@/store';
import router from '@/router';
import { ProfileInstance } from '@/interface/profile.interface';

class MethodsStore {
  public setupProfile(p: ProfileInstance): void {
    const profile = p;

    // set free account if user has not subscription or she expired;
    if (profile.subscription === null || !profile.subscription.active) {
      profile.subscription = { plan: SUBSCRIPTION_PLAN.free, active: true };
    }

    this.setInStore({ key: 'profile', payload: profile });

    localStorage.setItem('update', new Date().toISOString());
  }

  public logOut(): void {
    this.setInStore({ key: 'profile', payload: null });

    sessionStorage.removeItem('avatar');

    store.commit('setupToken', { hijacking: false, token: '' });
    store.commit('setupToken', { hijacking: true, token: '' });

    router.push({ name: 'log-in', force: true });
  }

  private setInStore(payload: { key: string, payload: any }): void {
    store.commit('set', payload);
  }
}

export default new MethodsStore();
