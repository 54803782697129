/* eslint-disable camelcase, max-len */

import { CropperResult, Point, Transform } from 'vue-advanced-cropper';

import { StripeCardCvcElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';

import { JOB_STATUS, JOB_TYPE } from './job.interface';

// BALANCE COMPONENT

export enum BALANCE_STATUS {
  used = 'used',
  prepaid = 'prepaid',
  overused = 'overused',
}

export interface BalanceResult {
  statuses: {
    value: BALANCE_STATUS;
    total: BALANCE_STATUS;
  }
  values: {
    value: number;
    total: number;
  }
  percents: {
    value: number;
    total: number;
  }
}

// STRIPE

export interface StripeCard {
  card: StripeCardNumberElementChangeEvent | null;
  expire: StripeCardExpiryElementChangeEvent | null;
  cvc: StripeCardCvcElementChangeEvent | null;
  name: string;
}

export enum CARD_BRAND {
  amex = 'amex',
  diners = 'diners',
  discover = 'discover',
  jcb = 'jcb',
  mastercard = 'mastercard',
  unionpay = 'unionpay',
  visa = 'visa',
}

// TUTORIAL

export enum TUTORIALS {
  upload = 'upload',
  options = 'options',
  mapping = 'mapping',
  purchase = 'purchase',
}

export interface TutorialsList {
  upload: boolean;
  options: boolean;
  mapping: boolean;
  purchase: boolean;
}

// DASHBOARD

export interface DashboardTableResult {
  downloaded: boolean;
  estimated_price: number;
  file: string;
  final_price: number | null
  id: number;
  job_type: JOB_TYPE;
  rows: number;
  status: JOB_STATUS;
  uploaded: string;
  valid_rows: number | null;
}

export interface DashboardTableResponse {
  count: number;
  next: string | null;
  previous: string | null
  results: DashboardTableResult[];
}

export type SearchDashboardJobs =
  | 'file'
  | 'job_type'
  | 'valid_rows'
  | 'uploaded'
  | 'estimated_price'
  | '-file'
  | '-job_type'
  | '-valid_rows'
  | '-uploaded'
  | '-estimated_price';

// LOADER

export enum LOADER_STATUS {
  pleaseWait = 'pleaseWait',
  error = 'error',
  uploading = 'uploading',
  finished = 'finished',
  stop = 'stop',
}

export enum LOADER_STATUS_TITLE {
  pleaseWait = 'Processing... Wait a moment',
  error = 'Uploading error',
  uploading = 'Uploading',
  finished = 'Finished',
}

export interface LoaderStopPoint {
  percent: number;
  timeManipulation: number;
}

// OTHER

export type SizesFile = 'Bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB';

export enum ROUTER_NAMES {
  auth = 'auth',
  logIn = 'log-in',
  signUp = 'sign-up',
  confirmAccount = 'confirm-account',
  identify = 'identify',
  resetPassword = 'reset-password',
  hijacking = 'hijacking',
  private = 'private',
  dashboard = 'dashboard',
  api = 'api',
  about = 'about',
  tryIt = 'try-it',
  pricing = 'pricing',
  settings = 'settings',
  profile = 'profile',
  billing = 'billing',
  ftp = 'ftp',
  forms = 'forms',
  test = 'test',
  oops = 'oops',
  catchError = 'catchError',
}

export type CropperInstance = {
  getResult: () => CropperResult;
  setCoordinates: (transform: Transform|Transform[]) => void;
  refresh: () => void;
  zoom: (factor: number, center?: Point) => void;
  move: (left: number, top?: number) => void;
  rotate: (angle: number) => void;
  flip: (horizontal: boolean, vertical?: boolean) => void;
  reset: () => void;
};

export interface NAICS {
  code: string | null,
  title: string | null,
}

export interface BlobParts {
  parts: Blob[];
  md5: string;
  name: string;
  size: number; // bite
}

export interface SimpleModalButton {
  title: string;
  value: any;
  button: 'fill' | 'border';
  style?: { [key: string]: any };
}

export interface ErrorConfirmAccount {
  non_field_errors: ['Link was already used'];
}

export type ReturnFile = () => File;

export type IconDirection = 'top-left' | 'top' | 'top-right' | 'right' | 'bottom-right' | 'bottom' | 'bottom-left';

export enum STATE_NAME {
  AK = 'Alaska',
  AL = 'Alabama',
  AR = 'Arkansas',
  AZ = 'Arizona',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  FL = 'Florida',
  GA = 'Georgia',
  IA = 'Iowa',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  MA = 'Massachusetts',
  MD = 'Maryland',
  ME = 'Maine',
  MI = 'Michigan',
  MN = 'Minnesota',
  MO = 'Missouri',
  MS = 'Mississippi',
  MT = 'Montana',
  NC = 'North Carolina',
  ND = 'North Dakota',
  NE = 'Nebraska',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NV = 'Nevada',
  NY = 'New York',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VA = 'Virginia',
  VT = 'Vermont',
  WA = 'Washington',
  WI = 'Wisconsin',
  WV = 'West Virginia',
  WY = 'Wyoming',
}

export type ABBREVIATION_STATE = keyof typeof STATE_NAME;
