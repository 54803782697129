import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';

import store from '@/store';
import { ROUTER_NAMES } from '@/interface/other.interface';

const guardPrivateZone = (next: NavigationGuardNext): void => {
  if (store.state.token || store.state.hijackingToken) next();
  else next('/log-in');
};

const guardAuthZone = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
  // for reset password from profile page;
  if (to.name === 'confirm-account' && from.name === 'profile') { next(); return; }

  if (to.name === 'hijacking') { next(); return; }

  if (store.state.token || store.state.hijackingToken) next('/dashboard');
  else next();
};

const guardTestPage = (next: NavigationGuardNext): void => {
  if (process.env.NODE_ENV === 'development') next();
  else next('/');
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: ROUTER_NAMES.auth,
    component: () => import('@/layouts/AuthWrapper.vue'),
    beforeEnter: (to, from, next) => guardAuthZone(to, from, next),
    children: [
      {
        path: '',
        redirect: 'log-in',
      },
      {
        path: 'log-in',
        name: ROUTER_NAMES.logIn,
        component: () => import('@/views/auth/LogIn.vue'),
      },
      {
        path: 'sign-up',
        name: ROUTER_NAMES.signUp,
        component: () => import('@/views/auth/SignUp.vue'),
      },
      {
        path: 'confirm-account',
        name: ROUTER_NAMES.confirmAccount,
        component: () => import('@/views/auth/ConfirmAccount.vue'),
      },
      {
        path: 'identify', // page for find email;
        name: ROUTER_NAMES.identify,
        component: () => import('@/views/auth/Identify.vue'),
      },
      {
        path: 'reset-password',
        name: ROUTER_NAMES.resetPassword,
        component: () => import('@/views/auth/ResetPassword.vue'),
      },
      {
        path: 'hijacking',
        name: ROUTER_NAMES.hijacking,
        component: () => import('@/views/auth/Hijacking.vue'),
      },
    ],
  },
  {
    path: '/',
    name: ROUTER_NAMES.private,
    component: () => import('@/layouts/PrivateWrapper.vue'),
    beforeEnter: (to, from, next) => guardPrivateZone(next),
    children: [
      {
        path: '/dashboard',
        name: ROUTER_NAMES.dashboard,
        component: () => import('@/views/dashboard/Dashboard.vue'),
      },
      {
        path: '/api',
        name: ROUTER_NAMES.api,
        component: () => import('@/layouts/APIWrapper.vue'),
        redirect: '/api/about',
        children: [
          {
            path: 'about',
            name: ROUTER_NAMES.about,
            component: () => import('@/views/api/About.vue'),
          },
          {
            path: 'try-it',
            name: ROUTER_NAMES.tryIt,
            component: () => import('@/views/api/TryIt.vue'),
          },
          {
            path: 'pricing',
            name: ROUTER_NAMES.pricing,
            component: () => import('@/views/api/Pricing.vue'),
          },
        ],
      },
      {
        path: '/settings',
        name: ROUTER_NAMES.settings,
        component: () => import('@/layouts/SettingsWrapper.vue'),
        redirect: '/settings/billing',
        children: [
          {
            path: 'profile',
            name: ROUTER_NAMES.profile,
            component: () => import('@/views/settings/profile/Profile.vue'),
          },
          {
            path: 'billing',
            name: ROUTER_NAMES.billing,
            component: () => import('@/views/settings/billing/Billing.vue'),
          },
          {
            path: 'ftp',
            name: ROUTER_NAMES.ftp,
            component: () => import('@/views/settings/ftp/FTP.vue'),
          },
          {
            path: 'forms',
            name: ROUTER_NAMES.forms,
            component: () => import('@/views/settings/forms/Forms.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/test',
    name: ROUTER_NAMES.test,
    beforeEnter: (to, from, next) => guardTestPage(next),
    component: () => import('@/views/Test.vue'),
  },
  {
    path: '/oops',
    name: ROUTER_NAMES.oops,
    component: () => import('@/views/errors/Oops.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: ROUTER_NAMES.catchError,
    redirect: '/oops',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
