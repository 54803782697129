import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["name", "id", "disabled", "checked"]
const _hoisted_2 = {
  key: 0,
  class: "u-s-n"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["base-radio", { 'disabled': _ctx.$props.disabled }])
  }, [
    _createElementVNode("input", {
      type: "radio",
      name: _ctx.$props.name,
      id: _ctx.$props.id,
      disabled: _ctx.$props.disabled,
      checked: _ctx.$props.checked,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleRadio && _ctx.handleRadio(...args)))
    }, null, 40, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass(["base-radio__circle", { active: _ctx.check }])
    }, null, 2),
    (!_ctx.$slots.title && _ctx.$props.title)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$props.title), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "title")
  ], 2))
}