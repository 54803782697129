import { AxiosPromise } from 'axios';

import http from '@/utils/http';
import { APIKeyResponse } from '@/interface/requests.interface';

class RequestAPI {
  public getAPIKey(): AxiosPromise<APIKeyResponse> {
    return http.get<APIKeyResponse>('/api/key/');
  }

  public sendSampleCode(code: Record<string, unknown>, api: string): AxiosPromise<any> {
    return http.post('/api/request/', code, { params: { url: `https://api.imdatacenter.com/1.0${api}` } });
  }
}

export default new RequestAPI();
