import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "modal__content" }
const _hoisted_2 = {
  key: 0,
  class: "modal__content--close"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconClose = _resolveComponent("IconClose")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal",
    style: _normalizeStyle({ zIndex: 1499 + _ctx.index})
  }, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      (!_ctx.$props.disabledClose)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", {
              class: "modal-close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
            }, [
              _createVNode(_component_IconClose)
            ])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ])), [
      [_directive_click_outside, _ctx.outsideCloseModal]
    ])
  ], 4))
}