/* eslint-disable */
export enum MONTH_LONG_TITLE {
  january = 'January',
  february = 'February',
  march = 'March',
  april = 'April',
  may = 'May',
  june = 'June',
  july = 'July',
  august = 'August',
  september = 'September',
  october = 'October',
  november = 'November',
  december = 'December',
}

export enum MONTH_SHORT_TITLE {
  jan = 'Jan',
  feb = 'Feb',
  mar = 'Mar',
  apr = 'Apr',
  may = 'May',
  jun = 'Jun',
  jul = 'Jul',
  aug = 'Aug',
  sep = 'Sep',
  oct = 'Oct',
  nov = 'Nov',
  dec = 'Dec',
}

export enum DAYS_WEEK_LONG_TITLE {
  sunday = 'Sunday',
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
}

export enum DAYS_WEEK_SHORT_TITLE {
  sun = 'Sun',
  mon = 'Mon',
  tue = 'Tue',
  wed = 'Wed',
  thu = 'Thu',
  fri = 'Fri',
  sat = 'Sat',
}
/* eslint-enable */

export interface ParserDate {
  year: {
    count: number;
    isLeapYear: boolean;
  };
  time: {
    hour: { 12: number | string; 24: number | string; };
    partDay: 'am' | 'pm';
    minute: number | string;
    second: number;
    millisecond: number;
  };
  day: {
    short: DAYS_WEEK_SHORT_TITLE;
    long: DAYS_WEEK_LONG_TITLE;
    weekdayNumber: number;
    day: number;
  };
  month: {
    short: MONTH_SHORT_TITLE;
    long: MONTH_LONG_TITLE;
    month: number;
  }
}

interface LongShortDateInfo<L, S> {
  long: L[];
  short: S[];
}

export const months: LongShortDateInfo<MONTH_LONG_TITLE, MONTH_SHORT_TITLE> = {
  long: Object.values(MONTH_LONG_TITLE),
  short: Object.values(MONTH_SHORT_TITLE),
};

export const days: LongShortDateInfo<DAYS_WEEK_LONG_TITLE, DAYS_WEEK_SHORT_TITLE> = {
  long: Object.values(DAYS_WEEK_LONG_TITLE),
  short: Object.values(DAYS_WEEK_SHORT_TITLE),
};

export const convertDateToString = (d: string | number): string | number => (Number(d) < 10 ? `0${d}` : d);

export const timeToAMPM = (h: number, m: string | number): {
  hour: { 12: string | number; 24: string | number; };
  minute: string | number;
  partDay: 'am' | 'pm';
} => {
  const ampm = h >= 12 ? 'pm' : 'am';
  const minute = convertDateToString(m);

  let hour: string | number = h % 12;
  hour = hour || 12;

  return { hour: { 12: hour, 24: h }, minute, partDay: ampm };
};

export const isLeapYear = (y: number): boolean => (!((y % 4) || (!(y % 100) && (y % 400))));

export const toParserDate = (d?: string): ParserDate => {
  const date = d ? new Date(d) : new Date();

  if (!d) {
    // adding time zone difference;
    const diffTimeZone = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - diffTimeZone);
  }

  const timeAMPM = timeToAMPM(date.getUTCHours(), date.getUTCMinutes());

  const dayCount = date.getUTCDay();
  const monthCount = date.getUTCMonth();

  const year = date.getUTCFullYear();

  return {
    year: {
      count: year,
      isLeapYear: isLeapYear(year),
    },
    time: {
      partDay: timeAMPM.partDay,
      hour: { 12: timeAMPM.hour[12], 24: timeAMPM.hour[24] },
      minute: timeAMPM.minute,
      second: date.getUTCSeconds(),
      millisecond: date.getUTCMilliseconds(),
    },
    day: {
      short: days.short[dayCount],
      long: days.long[dayCount],
      weekdayNumber: dayCount,
      day: date.getUTCDate(),
    },
    month: {
      short: months.short[monthCount],
      long: months.long[monthCount],
      month: monthCount + 1,
    },
  };
};
