/* eslint-disable  @typescript-eslint/no-explicit-any */

import
axios,
{
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
} from 'axios';

import store from '@/store';

class Http {
  public service!: AxiosInstance;

  constructor() {
    this.setAxios();
  }

  public post<T>(path: string, payload?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    this.setAxios();

    return this.service.post(path, payload, config);
  }

  public get<T>(path: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    this.setAxios();

    return this.service.get(path, { ...config });
  }

  public put<T>(path: string, payload: any): AxiosPromise<T> {
    this.setAxios();

    return this.service.put(path, payload);
  }

  public patch<T>(path: string, payload: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    this.setAxios();

    return this.service.patch(path, payload, config);
  }

  public delete<T>(path: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    this.setAxios();

    return this.service.delete(path, config);
  }

  private handleError(err: any): any {
    // TODO: add notify;
    throw err.response.data.error || 'Something has gone wrong. Please refresh page or contact support';
  }

  private setAxios() {
    const token = store.state.hijackingToken || store.state.token;

    const service: AxiosInstance = axios.create({
      baseURL: process.env.VUE_APP_BACKEND_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    this.service = service;
  }
}

export default new Http();
