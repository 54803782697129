
import { Options, Vue } from 'vue-class-component';

import { IconDirection } from '@/interface/other.interface';

@Options({
  props: {
    direction: String,
  },
})
export default class IconSmallArrow extends Vue {
  declare $props: {
    direction: IconDirection;
  }
}
