
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    text: Boolean,
    header: Boolean,
    black: Boolean,
  },
})
export default class Logo extends Vue {

}
