import { AxiosPromise } from 'axios';

import {
  FullJobInstance,
  JobMapFields,
  JOB_STATUS,
  JOB_TYPE,
  ShortJobInfoInstance,
} from '@/interface/job.interface';

import http from '@/utils/http';
import { toFormData } from '@/utils';
import { UserFTPCredentials } from '@/interface/profile.interface';
import {
  JobListRequest,
  JobListResponse,
  JobMapResponse,
  SampleFileResponse,
} from '@/interface/requests.interface';
import { EditedOptions, JobOptions } from '@/common/JobOptions/jobOptions.interface';

class RequestsDashboard {
  public getListJob(params: JobListRequest): AxiosPromise<JobListResponse> {
    return http.get<JobListResponse>('/dashboard/jobs/', { params });
  }

  public getMapFields(id: number): AxiosPromise<JobMapFields> {
    return http.get<JobMapFields>(`/dashboard/jobs/${id}/map`);
  }

  public getJobById(id: number): AxiosPromise<FullJobInstance> {
    return http.get<FullJobInstance>(`/dashboard/jobs/${id}`);
  }

  public updateJobStatus(id: number, status: JOB_STATUS): AxiosPromise<ShortJobInfoInstance> {
    return http.put<ShortJobInfoInstance>(`/dashboard/jobs/${id}`, toFormData({ status }));
  }

  public getJobOptions(): AxiosPromise<JobOptions> {
    return http.get<JobOptions>('/dashboard/jobs/options');
  }

  public getFTPCredentials(): AxiosPromise<UserFTPCredentials> {
    return http.get<UserFTPCredentials>('/dashboard/ftp/credentials');
  }

  public updateFTPPassword(password: string): AxiosPromise<UserFTPCredentials> {
    return http.put<UserFTPCredentials>('/dashboard/ftp/credentials', { password });
  }

  public updateOptions(options: { [key: string]: EditedOptions }): AxiosPromise<JobOptions> {
    return http.patch<JobOptions>('/dashboard/jobs/options', options);
  }

  public setJobDownload(id: number): AxiosPromise<FullJobInstance> {
    return http.put<FullJobInstance>(`/dashboard/jobs/${id}`, toFormData({ downloaded: true }));
  }

  /* eslint-disable camelcase */
  public updateJobMap(id: number, field_mapping: string[]): AxiosPromise<JobMapResponse> {
    return http.put<JobMapResponse>(`/dashboard/jobs/${id}/map`, { field_mapping, async: true });
  }

  public downloadSampleFile(job_type: JOB_TYPE): AxiosPromise<SampleFileResponse> {
    return http.get<SampleFileResponse>('/dashboard/sample', { params: { job_type } });
  }
  /* eslint-enable camelcase */
}

export default new RequestsDashboard();
