
import { Options, Vue } from 'vue-class-component';

import check from '@/icons/service/Check.vue';
import star from '@/icons/service/Star.vue';
import warning from '@/icons/service/Warning.vue';
import file from '@/icons/service/File.vue';
import remove from '@/icons/service/Remove.vue';
import refresh from '@/icons/service/Refresh.vue';
import exclamation from '@/icons/service/ExclamationPoint.vue';
import inProgress from '@/icons/service/Progress.vue';
import completedJob from '@/icons/service/CompletedJob.vue';
import briefcase from '@/icons/service/Briefcase.vue';
import lock from '@/icons/service/Lock.vue';
import house from '@/icons/service/House.vue';
import person from '@/icons/service/Person.vue';
import clipboard from '@/icons/service/Clipboard.vue';
import smallArrow from '@/icons/service/SmallArrow.vue';
import close from '@/icons/service/Close.vue';
import email from '@/icons/service/Email.vue';
import phone from '@/icons/service/Phone.vue';
import archive from '@/icons/service/Archive.vue';

@Options({
  components: {
    check,
    star,
    warning,
    file,
    remove,
    refresh,
    exclamation,
    inProgress,
    completedJob,
    briefcase,
    lock,
    house,
    person,
    clipboard,
    smallArrow,
    close,
    email,
    phone,
    archive,
  },
  props: {
    icon: String,
  },
})
export default class IconsService extends Vue {}
