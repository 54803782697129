import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "simple-modal" }
const _hoisted_2 = { class: "simple-modal__titles" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "simple-modal__buttons" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    class: "simple-modal__container",
    onClose: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", {
            class: "t-a-c",
            innerHTML: _ctx.$props.title
          }, null, 8, _hoisted_3),
          _createElementVNode("p", {
            class: "t-a-c m-top-5",
            innerHTML: _ctx.$props.subtitle
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props?.buttons, (btn, id) => {
            return (_openBlock(), _createElementBlock("button", {
              key: id,
              class: _normalizeClass(["btn", btn.button]),
              style: _normalizeStyle(btn.style),
              onClick: ($event: any) => (_ctx.handleClick(btn.value))
            }, _toDisplayString(btn.title), 15, _hoisted_6))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}