/* eslint-disable max-len */

export enum SUBSCRIPTION_PLAN {
  premium = 'premium',
  standard = 'standard',
  free = 'free',
}

export enum SUBSCRIPTION_PLAN_TITLE {
  premium = 'Premium user',
  standard = 'Standard user',
  free = 'Free account',
}

export enum SUBSCRIPTION_PLAN_ALTERNATIVE_TITLE {
  premium = 'Premium',
  standard = 'Standard',
  free = 'Free User',
}

interface SubscriptionPermission {
  title: string;
  include: boolean;
}

export interface SubscriptionInstance {
  title: SUBSCRIPTION_PLAN_TITLE;
  alternativeTitle: SUBSCRIPTION_PLAN_ALTERNATIVE_TITLE;
  price: number;
  permission: SubscriptionPermission[];
  description: string;
}

type Subscriptions = {
  free: SubscriptionInstance;
  premium: SubscriptionInstance;
  standard: SubscriptionInstance;
}

const freePermission: SubscriptionPermission[] = [
  { title: 'No minimum job cost', include: false },
  { title: 'FTP access', include: false },
  { title: 'API access', include: false },
];

const standardPermission: SubscriptionPermission[] = [
  { title: 'No minimum job cost', include: true },
  { title: 'FTP access', include: true },
  { title: 'API access', include: false },
];

const premiumPermission: SubscriptionPermission[] = [
  { title: 'No minimum job cost', include: true },
  { title: 'FTP access', include: true },
  { title: 'API access', include: true },
];

export const subscriptions: Subscriptions = {
  free: {
    title: SUBSCRIPTION_PLAN_TITLE.free,
    alternativeTitle: SUBSCRIPTION_PLAN_ALTERNATIVE_TITLE.free,
    price: 0,
    permission: freePermission,
    description: 'Payment will be made for each job separately. <br /> Most jobs have a minimum cost',
  },
  standard: {
    title: SUBSCRIPTION_PLAN_TITLE.standard,
    alternativeTitle: SUBSCRIPTION_PLAN_ALTERNATIVE_TITLE.standard,
    price: 450,
    permission: standardPermission,
    description: 'If the costs exceed their subscription, you will be billed for additional records. These subscription is agreed to on an annual basis.',
  },
  premium: {
    title: SUBSCRIPTION_PLAN_TITLE.standard,
    alternativeTitle: SUBSCRIPTION_PLAN_ALTERNATIVE_TITLE.standard,
    price: 900,
    permission: premiumPermission,
    description: ' If the costs exceed their subscription, you will be billed for additional records. These subscription is agreed to on an annual basis.',
  },
};

// LIST ACCESSIBLE PLAN FOR CONNECTING API;
export const accessibleAPIPlan: SUBSCRIPTION_PLAN[] = [SUBSCRIPTION_PLAN.premium];
