
import { Options, Vue } from 'vue-class-component';
import { WatchStopHandle } from 'vue';
import { mapState } from 'vuex';

import ScreenIsNotSupported from '@/common/ScreenIsNotSupported.vue';

import Hijacking from '@/common/HijackingStripe.vue';
import methods from './methods';
import { toParserDate } from './utils/date';
import requests from './requests';
import { ToggleModal } from './store/modules/modals';

@Options({
  components: {
    ScreenIsNotSupported,
    Hijacking,
  },
  computed: {
    ...mapState(['isSmartphone', 'countOpenedModal', 'hijackingToken']),
  },
})
export default class App extends Vue {
  public watchStopHandle: WatchStopHandle | null = null;

  private updateWidth() {
    if (window.innerWidth <= 1024) {
      this.$store.commit('changeIsSmartphone', true);
    } else {
      this.$store.commit('changeIsSmartphone', false);
    }
  }

  private checkAppVersion(): void {
    const version = process.env.VUE_APP_VERSION;

    if (localStorage.getItem('version') !== version) {
      localStorage.setItem('version', version);
      methods.store.logOut();
    }
  }

  private resaveProfile(): void {
    const lastUpdate = localStorage.getItem('update') || '';

    if (new Date(lastUpdate).toString() !== 'Invalid Date') {
      const currentDate = toParserDate();
      const date = toParserDate(lastUpdate);

      // eslint-disable-next-line
      if (date.year.count !== currentDate.year.count || date.month.month !== currentDate.month.month || date.day.day !== currentDate.day.day) {
        this.getProfile();
      }
    } else {
      this.getProfile();
    }
  }

  private getProfile(): void {
    requests.profile.getProfile()
      .then((res) => { methods.store.setupProfile(res.data); });
  }

  private checkFirstLoginToApp(): void {
    if (this.$store.state.profile) {
      if (this.watchStopHandle !== null) { this.watchStopHandle(); }

      const cd = toParserDate(); // Current date
      const dj = toParserDate(this.$store.state?.profile?.date_joined); // Date joined

      // eslint-disable-next-line
      if ((cd.day.day === dj.day.day && cd.month.month === dj.month.month && cd.year.count === dj.year.count) && !localStorage.getItem('start')) {
        this.showStartUsingModal();
      }
    } else {
      this.watchStopHandle = this.$store.watch((state) => state.profile, this.checkFirstLoginToApp);
    }
  }

  private showStartUsingModal(): void {
    this.$store.commit('modals/toggle', { modal: 'changeSubscription', status: true } as ToggleModal);

    localStorage.setItem('start', 'true');
  }

  created(): void {
    this.checkFirstLoginToApp();
    this.updateWidth();
    this.checkAppVersion();
    this.resaveProfile();

    window.addEventListener('resize', this.updateWidth);

    this.$store.commit('resetState');
    this.$store.commit('modals/reset');
  }

  unmounted(): void {
    window.removeEventListener('resize', this.updateWidth);
  }
}
