
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {

  },
  props: {
    id: String,
    name: String,
    title: String,
    disabled: Boolean,
    checked: Boolean,
    value: {
      type: [String, Number, Object, Boolean],
      default: null,
    },
  },
  emits: {
    selected: Boolean,
    'update:value': [String, Number, Object, Boolean],
  },
})
export default class BaseRadioInput extends Vue {
  declare $props: {
    id: string;
    name: string;
    title: string;
    disabled: boolean;
    checked: boolean;
    value: string | number | null;
  }

  public check = false;

  public handleRadio(): void {
    this.$emit('selected', this.$props.value || true);
    this.$emit('update:value', this.$props.value || null);
  }
}
