import { createApp } from 'vue';

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

import vClickOutside from 'click-outside-vue3';
import PerfectScrollbar from 'vue3-perfect-scrollbar';

import baseInputs from '@/common/base/_global';
import App from './App.vue';
import router from './router';
import store from './store';

import 'animate.css';
import 'normalize.css';
import '@/style/style.scss';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

const app = createApp(App);

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//   app,
//   dsn: "https://07e50191fdc84e0b8581770670293ead@o532778.ingest.sentry.io/5731237",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
//   logErrors: true,
//   environment: "production"
//   });
// }

baseInputs(app);
app
  .use(store)
  .use(router)
  .use(vClickOutside)
  .use(PerfectScrollbar)
  .mount('body');
