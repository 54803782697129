import { JOB_TYPE } from '@/interface/job.interface';

/* eslint-disable camelcase, max-len */
export const socialLinks = {
  facebook: 'https://www.facebook.com/BrooksIntegratedMarketing/',
  linkedIn: 'https://www.linkedin.com/company/brooks-integrated-marketing',
  twitter: 'https://twitter.com/_brooksim',
};

export const footerLinks = {
  privacy_policy: 'https://www.brooksim.com/privacy-policy',
  privacy_form: 'https://www.brooksim.com/privacy-form',
};

export const docusig_uri = `https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature&client_id=${process.env.VUE_APP_DOCUSIGN_INTEGRATION_KEY}&redirect_uri=${window.location.origin}/profile/forms`;

/**
 * @description size is in bytes (binary number);
 * @link https://www.gbmb.org/mb-to-bytes;
 */
export const maxFileSize = 367001600;

export enum VALID_FORMAT_TYPE_FILE {
  csv = 'csv',
  xls = 'xls',
  xlsx = 'xlsx',
}

/**
 * @link https://www.ibm.com/docs/en/wkc/cloud?topic=catalog-previews;
 */
export enum VALID_MIME_TYPE_FILE {
  csv = 'text/csv',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const inactiveJobTypes: JOB_TYPE[] = [JOB_TYPE.canadian_ncoa];
