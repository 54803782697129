import axios, { AxiosPromise } from 'axios';

import { LoginResponse } from '@/interface/requests.interface';

class RequestsAuth {
  private API_URL = process.env.VUE_APP_BACKEND_URL;

  public login(query: { username: string, password: string }): AxiosPromise<LoginResponse> {
    return axios
      .get(`${this.API_URL}/profile/login/`, {
        headers: {
          Authorization: `Basic ${btoa(`${query.username}:${query.password}`)}`,
        },
      });
  }
}

export default new RequestsAuth();
